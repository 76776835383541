<template>
  <div>
    <qrcode-stream @decode="onDecode" class="fill-height"></qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "DaySelector",
  props: {},
  components: { QrcodeStream },
  data: () => ({
    scan: "",
  }),

  methods: {
    async onInit(promise) {
      // show loading indicator
      this.$store.commit("SET_APP_LOADING", true);

      try {
        const { capabilities } = await promise;
        console.log(capabilities);
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
        this.$store.commit("SET_APP_LOADING", false);
      }
    },

    onDecode(decoededString) {
      this.scan = decoededString;
      this.$emit("onDecode", decoededString);
    },
  },
  computed: {},
  created() {},

  mounted() {},
};
</script>

<style scoped></style>
