<template>
  <v-container>
    <v-card v-if="!actualOrder || actualOrder.length == 0">
      <v-card-text class="text-center">
        <h3>Derzeit keine Bestellung vorhanden</h3>
      </v-card-text>
    </v-card>

    <v-row v-if="actualOrder && actualOrder.length > 0">
      <v-col>
        <v-card>
          <v-card-title>
            {{ actualOrder[0].days.day }}
            {{ getTimeSlot(actualOrder[0].days.timeSlot) }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <div
                v-for="(day, index) in actualOrder"
                :key="index"
                class="mealRow"
              >
                <v-row>
                  <v-col cols="2"> {{ day.days.amount }}x </v-col>
                  <v-col>
                    {{ day.days.meal.name }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col dense
                    ><v-chip
                      label
                      outlined
                      :color="day.days.pickup ? 'green' : 'orange'"
                    >
                      Status: {{ pickupStatus(day.days) }}</v-chip
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="Customer.anlieferorte.length >= 0 && !day.days.pickup"
                >
                  <v-col>
                    <v-btn block color="secondary" @click="pickupScan(day.days)"
                      ><v-icon class="mr-2">mdi-qrcode-scan</v-icon> Code
                      scannen</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-if="Customer.roboters.length > 0 && !day.days.pickup">
                  <v-col>
                    <v-img :src="day.days.qrCode" style="max-width: 300px" />
                  </v-col>
                </v-row>
                <hr class="mb-4 mt-4" />
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showOrderPickupResult" fullscreen>
      <v-card dark @click="showOrderPickupResult = false">
        <v-card-text>
          <v-container v-show="resultStatus">
            <v-row>
              <v-col style="text-align: center">
                <h1>Check erfolgreich</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="resultText">
                Dein Gericht wurde gefunden!<br />
                Du kannst dir davon<br />
                <span class="amount">{{ mealAmount }} Stk.</span><br />
                nehmen.
              </v-col>
            </v-row>
            <v-layout align-center justify-center class="mt-4">
              <v-flex align-center style="text-align: center">
                <v-icon size="100" color="green"
                  >mdi-check-circle-outline</v-icon
                >
              </v-flex>
            </v-layout>
          </v-container>
          <v-container v-show="!resultStatus">
            <v-row>
              <v-col style="text-align: center">
                <h1>Check fehlgeschlagen</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-show="!checkError" class="errorText">
                Dein Gericht wurde nicht gefunden!<br />
                Leider trat ein Fehler auf<br />
              </v-col>
              <v-col v-show="checkError" class="errorText">
                {{ checkError }}
              </v-col>
            </v-row>
            <v-layout align-center justify-center class="mt-4">
              <v-flex align-center style="text-align: center">
                <v-icon size="100" color="red">mdi-alert-outline</v-icon>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="scanCode" fullscreen>
      <v-toolbar dark>
        <v-toolbar-title>QR Code scannen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="scanCode = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card dark class="fill-height">
        <v-card tile class="scanHeader">
          <v-card-text class="scanHeader"
            >Bitte scanne den QR Code auf deinem Gericht ab</v-card-text
          >
        </v-card>
        <QRCodeScanner v-if="scanCode" @onDecode="onDecode" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import QRCodeScanner from "@/components/order/QRCodeScanner";
import OrderService from "@/services/OrderService";

export default {
  name: "Pickup",
  components: { QRCodeScanner },
  data: () => ({
    order: null,
    scanCode: false,
    mealAmount: 0,
    pickupMeal: "",
    checkError: "",
    resultStatus: false,
  }),
  methods: {
    pickupScan(item) {
      this.scanCode = true;
      this.pickupMeal = item;
    },
    /**
     * @param {string} decodedString - Decoded QR Code as String
     */
    onDecode(decodedString) {
      let qrParts = {
        artnr: "",
        mhd: "",
        charge: "",
      };
      const idArtNr = "(241)";
      const idMhd = "(15)";
      const idCharge = "(10)";
      this.checkError = null;
      this.resultStatus = false;

      qrParts.artnr = String(decodedString).substring(
        idArtNr.length,
        decodedString.indexOf(idMhd)
      );
      qrParts.mhd = String(decodedString).substring(
        decodedString.indexOf(idMhd) + idMhd.length,
        decodedString.indexOf(idCharge)
      );
      qrParts.charge = String(decodedString).substring(
        decodedString.indexOf(idCharge) + idCharge.length
      );

      const mhdDate = moment(qrParts.mhd, "YYMMDD");

      if (mhdDate.isBefore(moment())) {
        //Abgelaufen
        this.checkError = `Das abgescannte Gericht ist leider am ${mhdDate.format(
          "DD.MM.YYYY"
        )} abgelaufen! Bitte entsorge dieses Gericht`;
        this.checkError = `Das Gericht stimmt nicht mit dem abgescannten Gericht überein. Bitte Scanne das richtige Gericht.`;
        this.resultStatus = false;
        this.dlgResult = true;
        this.scanCode = false;
        return false;
      }

      if (Number.parseInt(qrParts.artnr) !== this.pickupMeal.meal.gerichtnr) {
        this.checkError = `Das Gericht stimmt nicht mit dem abgescannten Gericht überein. Bitte Scanne das richtige Gericht.`;
        this.resultStatus = false;
        this.dlgResult = true;
        this.scanCode = false;
        return false;
      }

      OrderService.checkQRScan(qrParts.artnr, this.actualOrder[0]._id)
        .then((response) => {
          if (response.data.data.result == true && response.data.data.meal) {
            this.mealAmount = response.data.data.meal.days.amount;
            this.resultStatus = true;

            this.pickupMeal.pickup = true;
          } else {
            this.resultStatus = false;
          }
        })
        .catch((error) => {
          this.resultStatus = false;
          this.checkError = error;
        })
        .finally(() => {
          this.scanCode = false;
          this.dlgResult = true;
        });
    },
    bestellEnde(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getDayName(date) {
      if (!(date instanceof moment)) {
        date = moment(date, "DD.MM.YYYY");
      }
      return moment(date).format("dddd");
    },
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTimeSlot(index) {
      const timeSlot = this.Customer.mahlzeiten[index];
      return `${timeSlot.von} - ${timeSlot.bis}`;
    },
    currencyFormat(value = 0) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
    totalMeals(item) {
      let sum = 0;
      for (let index in item.days) {
        sum += item.days[index].amount;
      }
      return sum;
    },

    isCurrentOrder(order) {
      return order._id === this.currentOrder._id;
    },
    pickupStatus(item) {
      if (item.check) {
        return "Gericht überprüft, in Auslieferung";
      } else if (item.pickup) {
        return "Ausgeliefert";
      } else {
        return "Wartend";
      }
    },
  },
  computed: {
    viewPane: {
      get() {
        return this.$store.getters["getViewPane"];
      },
      set(id) {
        this.$store.commit("SET_VIEW_PANE", id);
      },
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    Customer() {
      return this.$store.getters["customer/getCustomer"];
    },

    actualOrder() {
      return this.$store.getters["order/getActualOrder"];
    },

    orderDays() {
      return this.$store.getters["order/getOrderDays"];
    },
    daySortedOrder() {
      return this.$store.getters["order/daySortedOrder"];
    },

    lastOrderSave() {
      return moment(this.actualOrder.modifiedTS).format("DD.MM.YYYY HH:mm:ss");
    },
    showOrderPickupResult: {
      get() {
        return this.$store.getters["order/isOrderPickupResultDialogShown"];
      },
      set(value) {
        this.$store.commit("order/TOGGLE_ORDER_PICKUP_RESULT", value);
      },
    },
  },

  updated() {},
};
</script>

<style scoped>
.v-dialog--fullscreen {
  overflow: hidden !important;
}
.currentOrder {
  margin-left: 10px;
  font-size: 14px;
  color: green;
}
.scanHeader {
  background-color: green;
  color: white;
  font-size: 1.2em;
}

.mealRow {
  font-size: 16px;
  font-weight: bold;
}

.resultText {
  font-size: 1.7em;
  line-height: 1.7em;
  text-align: center;
}

.errorText {
  font-size: 1.7em;
  line-height: 1.7em;
  text-align: center;
}

.amount {
  font-weight: bold;
  color: green;
}
</style>
